import React from 'react'

const Home = () => {
  return (
    <>
      <h1 className="title">Leta Keane</h1>
      <h2 className="subheading">weird analogies, helpful diagrams, ok jokes</h2>
    </>
  )
}

export default Home;